@tailwind base;
@tailwind components;
@tailwind utilities;


/* import the DropDownButton dependency styles */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

root {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  scroll-margin-left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* border-radius: 100vh; */
  @apply bg-gray-500 border-0 border-l-4 border-transparent
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* border-radius: 100vh; */
  @apply border-0 border-l-2 border-gray-500 border-solid bg-gray-600
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-800
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

.Overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(31, 91, 133, 1);
}

.animating {
  position: absolute;
  width: 100vw;
  height: 0vh;
  z-index: 9999;
  background-color: rgba(31, 91, 133, 1);
  animation: curtain 5s linear;
}

.hideHeader {
  background-color: #ddf0ff;
}

.bgWhite {
  background-color: #fff;
  padding: 15px 10px;
}

@keyframes curtain {
  0% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
}

.curtain {
  animation: curtain 5s linear;
}

.bn632-hover {
  width: 100vw;
  height: 65px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn632-hover.bn18 {
    background-image: linear-gradient(
      to right,
      #25aae1,
      #40e495,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

/* custom code start */
